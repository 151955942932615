import React from 'react';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import cx from 'classnames';
import {
  faArrowRotateLeft,
  faFaceFrownSlight,
  faExclamationTriangle,
  faFaceSadTear,
  faXmark,
  faTrash,
  faPenToSquare,
  faBoltLightning,
  faGear,
  faBan,
  faArrowRight,
  faArrowRightLong,
  faRightLeft,
  faBuildingUser,
  faArrowUpRightFromSquare,
  faCircleUser,
  faTriangleExclamation,
  faPlus,
  faEye,
  faThumbsUp,
  faThumbsDown,
  faQuestion,
  faGrid,
  faArrowUpFromBracket,
  faBriefcase,
  faPrint,
  faEnvelope,
  faUserPlus as faUserPlusSolid,
  faCaretUp,
  faCaretDown,
  faPencil,
  faBuilding,
  faBuildingColumns,
  faUser,
  faUsers,
  faDollarSign,
  faFileLines,
  faCircleInfo,
  faCheck as faCheckSolid,
  faCircleCheck,
  faArrowLeft,
  faCircleQuestion as faCircleQuestionSolid,
  faRedo,
  faDownload as faDownloadSolid,
  faCrown,
  faArrowRightToLine,
  faCardsBlank,
  faCircle,
  faMerge,
  faTrophyStar,
  faArrowRightArrowLeft as faArrowRightArrowLeftSolid,
  faGlobe,
  faLanguage,
  faRankingStar,
  faMedal,
  faKey,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faUserPlus,
  faUserMinus,
  faCreditCard,
  faTrash as faTrashRegular,
  faAlarmExclamation,
  faArrowsRotate,
  faArrowRightArrowLeft,
  faCircleQuestion,
  faAngleUp,
  faAngleDown,
  faEyeSlash as faEyeSlashRegular,
  faCheck,
  faCopy as faCopyRegular,
  faPencil as faPencilRegular,
  faEnvelope as faEnvelopeRegular,
  faPrint as faPrintRegular,
  faLaptop as faLaptopRegular,
  faComments as faCommentsRegular,
  faTrophy as faTrophyRegular,
  faRepeat,
  faLock,
  faTrashCan,
  faTriangleExclamation as faTriangleExclamationReg,
  faMagnifyingGlass,
  faFaceFrownSlight as faFaceFrownSlightReg,
  faChevronLeft,
  faChevronRight,
  faRotateExclamation,
  faFileInvoiceDollar,
  faExclamationCircle,
  faDownload,
  faExclamationTriangle as faExclamationTriangleReg,
  faBuilding as faBuildingRegular,
  faBuildingColumns as faBuildingColumnsRegular,
  faCity as faCityRegular,
  faBan as faBanRegular,
  faAdd,
  faGear as faGearRegular,
  faClipboardList,
  faEnvelope as faEnvelopeReg,
  faFileLines as faFileLinesRegular,
  faCircleDot,
  faCalendar,
  faShieldCheck,
  faCalendarCirclePlus,
  faFaceParty,
  faJackOLantern,
  faTurkey,
  faGift,
  faBell,
  faChampagneGlasses,
  faSnowflake,
  faFlowerDaffodil,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faPen,
  faShieldHalved,
  faFileLines as faFileLinesLight,
  faArrowRight as faArrowRightLight,
} from '@fortawesome/pro-light-svg-icons';

export const icons = {
  faArrowRotateLeft,
  faFaceFrownSlight,
  faExclamationTriangle,
  faFaceSadTear,
  faXmark,
  faUserPlus,
  faUserPlusSolid,
  faUserMinus,
  faCreditCard,
  faAlarmExclamation,
  faArrowsRotate,
  faTrash,
  faTrashRegular,
  faPenToSquare,
  faCircleQuestion,
  faBoltLightning,
  faGear,
  faArrowRightLong,
  faRightLeft,
  faRankingStar,
  faAngleUp,
  faAngleDown,
  faBuildingUser,
  faCopyRegular,
  faBan,
  faEnvelope,
  faEnvelopeRegular,
  faLaptopRegular,
  faCommentsRegular,
  faTrophyRegular,
  faQuestion,
  faThumbsUp,
  faThumbsDown,
  faCheck,
  faArrowUpRightFromSquare,
  faCircleUser,
  faTriangleExclamation,
  faPlus,
  faGrid,
  faEye,
  faEyeSlashRegular,
  faArrowUpFromBracket,
  faRepeat,
  faBriefcase,
  faLock,
  faPen,
  faShieldHalved,
  faShieldCheck,
  faTrashCan,
  faPrint,
  faPrintRegular,
  faTriangleExclamationReg,
  faCaretUp,
  faCaretDown,
  faMagnifyingGlass,
  faFaceFrownSlightReg,
  faPencil,
  faPencilRegular,
  faBuilding,
  faUser,
  faUsers,
  faDollarSign,
  faFileLines,
  faCircleInfo,
  faChevronLeft,
  faChevronRight,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRightArrowLeftSolid,
  faArrowRightLight,
  faRotateExclamation,
  faFileInvoiceDollar,
  faCheckSolid,
  faExclamationCircle,
  faDownload,
  faArrowLeft,
  faExclamationTriangleReg,
  faCircleQuestionSolid,
  faRedo,
  faDownloadSolid,
  faBuildingRegular,
  faBuildingColumns,
  faBuildingColumnsRegular,
  faCityRegular,
  faBanRegular,
  faAdd,
  faGearRegular,
  faClipboardList,
  faCrown,
  faArrowRightToLine,
  faCardsBlank,
  faEnvelopeReg,
  faFileLinesRegular,
  faFileLinesLight,
  faCircleDot,
  faCircle,
  faMerge,
  faCalendar,
  faCalendarCirclePlus,
  faTrophyStar,
  faFaceParty,
  faJackOLantern,
  faTurkey,
  faGift,
  faBell,
  faChampagneGlasses,
  faSnowflake,
  faFlowerDaffodil,
  faCircleCheck,
  faGlobe,
  faLanguage,
  faMedal,
  faVideo,
  faKey,
};

export type Name = keyof typeof icons;

export type Color =
  | 'white'
  | 'black'
  | 'primaryLight'
  | 'primaryMain'
  | 'primaryDark'
  | 'primaryContrastText'
  | 'successMain'
  | 'successLight'
  | 'warning'
  | 'error'
  | 'grey300'
  | 'grey400'
  | 'grey600'
  | 'blueLight'
  | 'animated';

export type IconFontAwesomeProps = {
  name: Name;
  size?: SizeProp;
  color?: Color;
  isCenter?: boolean;
  isBackground?: boolean;
  isTooltip?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  sx?: SxProps<Theme>;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'inherit',
  '&.white svg': {
    color: '#fff',
  },
  '&.white.isBackground svg': {
    background: '#fff',
  },

  '&.black svg': {
    color: '#000',
  },
  '&.black.isBackground svg': {
    background: '#000',
  },

  '&.primaryLight svg': {
    color: theme.palette.primary.light,
  },
  '&.primaryLight.isBackground svg': {
    background: theme.palette.primary.light,
  },

  '&.primaryMain svg': {
    color: theme.palette.primary.main,
  },
  '&.primaryMain.isBackground svg': {
    background: theme.palette.primary.main,
  },

  '&.primaryDark svg': {
    color: theme.palette.primary.dark,
  },
  '&.primaryDark.isBackground svg': {
    background: theme.palette.primary.dark,
  },

  '&.primaryContrastText svg': {
    color: theme.palette.primary.contrastText,
  },
  '&.primaryContrastText.isBackground svg': {
    background: theme.palette.primary.contrastText,
  },

  '&.successMain svg': {
    color: theme.palette.success.main,
  },
  '&.successMain.isBackground svg': {
    background: theme.palette.success.main,
  },

  '&.animated svg': {
    color: theme.palette.success.main,
    animation: 'myEffectExit 6000ms infinite',
  },
  '@keyframes myEffectExit': {
    '0%': {
      color: theme.palette.success.main,
    },
    '50%': {
      color: theme.palette.warning.main,
    },
    '100%': {
      color: theme.palette.success.main,
    },
  },

  '&.successLight svg': {
    color: theme.palette.success.light,
  },
  '&.successLight.isBackground svg': {
    background: theme.palette.success.light,
  },

  '&.warning svg': {
    color: theme.palette.warning.main,
  },
  '&.warning.isBackground svg': {
    background: theme.palette.warning.main,
  },

  '&.error svg': {
    color: theme.palette.error.main,
  },
  '&.error.isBackground svg': {
    background: theme.palette.error.main,
  },

  '&.grey300 svg': {
    color: theme.palette.grey[300],
  },
  '&.grey300.isBackground svg': {
    background: theme.palette.grey[300],
  },

  '&.grey400 svg': {
    color: theme.palette.grey[400],
  },
  '&.grey400.isBackground svg': {
    background: theme.palette.grey[400],
  },

  '&.blueLight svg': {
    color: theme.palette.dhqBlue.light,
  },
  '&.blueLight.isBackground svg': {
    background: theme.palette.dhqBlue.light,
  },

  '&.grey600 svg': {
    color: theme.palette.grey[600],
  },
  '&.grey600.isBackground svg': {
    background: theme.palette.grey[600],
  },

  '&.isBackground svg': {
    padding: 10,
    color: '#fff',
    borderRadius: '50%',
  },

  '&.isCenter': {
    display: 'flex',
    justifyContent: 'center',
  },

  '&.isTooltip': {
    svg: {
      width: 15,
      height: 15,
      marginBottom: theme.spacing(1),
      padding: 3,
      background: theme.palette.grey[400],
      borderRadius: '50%',
      color: '#fff',
    },
  },
}));

const IconFontAwesome: React.FC<IconFontAwesomeProps> = ({
  name,
  size = 'lg',
  color,
  isCenter,
  isBackground,
  isTooltip,
  onClick,
  sx,
}) => {
  return (
    <StyledBox
      className={cx(color, { isCenter, isBackground, isTooltip })}
      onClick={onClick}
      sx={sx}
    >
      <FontAwesomeIcon icon={icons[name] as IconProp} size={size} />
    </StyledBox>
  );
};

export default IconFontAwesome;
