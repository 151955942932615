/*
Routes
================================================================================
These are the pages you can go to.  They are all wrapped in the App component,
which should contain the navbar etc

See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
about the code splitting business.

**Organization:** The routes are listed alphabetically.
*/
import getHooks from 'utils/hooks';
import findRoute from './new/routing/routes';

const errorLoading = (err) => {
  /* eslint-disable no-console */
  console.error('Dynamic page loading failed', err);
  /* eslint-enable no-console */
  window.location.reload();
};

const loadModule = (cb) => (componentModule) => {
  cb(null, componentModule.default);
};

export default function createRoutes(store) {
  // Create reusable async injectors using getAsyncInjectors factory
  const {
    injectReducer,
    injectSagas,
    redirectToDashboard,
    redirectToLogin,
    redirectTo404,
    redirectToHome,
    redirectToSignup,
    logoutReducer,
  } = getHooks(store);

  return [
    {
      path: findRoute('EnrollPage').path,
      name: 'EnrollPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/EnrollPage/pages/EnrollPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: '/checkout',
      onEnter: (nextState, replace) => {
        replace(findRoute('CheckoutPage').path);
      },
    },
    {
      path: findRoute('CheckoutPage').path,
      name: 'CheckoutPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/Checkout/pages/CheckoutPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('MembershipStandardOnlyPage').path,
      name: 'MembershipStandardOnlyPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/MembershipPlan/pages/MembershipPlanStandardOnlyPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('EmbeddedMembershipPage').path,
      name: 'EmbeddedMembershipPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/MembershipPlan/pages/EmbeddedEnroll'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('SingleSetupPage').path,
      name: 'SingleSetupPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/Setup/pages/SingleLocationSetupPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('MultiSetupPage').path,
      name: 'MultiSetupPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/Setup/pages/MultiLocationsSetupPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('MultiSetupCSVReview').path,
      name: 'MultiSetupCSVReview',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/Setup/pages/MultiLocationsCSVReviewPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('TestPlaygroundPage').path,
      name: 'TestPlaygroundPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/TestPlayground/pages/TestPlaygroundPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('TestLayoutPage').path,
      name: 'TestLayoutPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/TestPlayground/pages/TestLayoutPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('BillingPage').path,
      name: 'BillingPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('new/modules/BillingPage/pages/BillingPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('AnalyticDashboardPage').path,
      name: 'AnalyticDashboardPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/AnalyticDashboardPage/pages/AnalyticDashboardPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('MultiLocationDashboardPage').path,
      name: 'MultiLocationDashboardPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/MultiLocationDashboard/pages/MultiLocationDashboardPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('MembersPage').path,
      name: 'MembersPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('new/modules/MembersPage/pages/MembersPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('InternalPatientSignup').path,
      name: 'InternalPatientSignup',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/InternalPatientSignup/pages/InternalPatientSignupPage'
          ),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: findRoute('ExternalPatientSignup').path,
      name: 'ExternalPatientSignup',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/ExternalPatientSignup/pages/ExternalPatientSignupPage'
          ),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('BusinessConnectPage').path,
      name: 'BusinessConnectPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/BusinessConnect/pages/BusinessConnectPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('CommunityConnectPage').path,
      name: 'CommunityConnectPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/CommunityConnect/pages/CommunityConnectPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('MarketingSpace').path,
      name: 'MarketingSpace',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/MarketingSpace/pages/MarketingSpacePage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('MembershipPlansManage').path,
      name: 'MembershipPlansManage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/MembershipPlansManage/pages/MembershipPlansManage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: '/account/settings/membership-plan-legacy',
      onEnter: (nextState, replace) => {
        replace('/account/settings/membership-plan');
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('OfficeSettings').path,
      name: 'OfficeSettings',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/OfficeSettings/pages/OfficeSettingsPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('Reports').path,
      name: 'Reports',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('new/modules/Reports/pages/ReportsPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([dentistMembersReducer, dentistMembersSagas, component]) => {
            injectReducer('dentistMembersPage', dentistMembersReducer.default);
            injectSagas(dentistMembersSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('Disputes').path,
      name: 'Disputes',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/Disputes/pages/DisputesPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('InhouseFinancing').path,
      name: 'InhouseFinancing',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/InhouseFinancing/pages/InhouseFinancingPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('UserSettings').path,
      name: 'UserSettings',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/UserSettings/pages/UserSettingsPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('ResetPage').path,
      name: 'ResetPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/LoginPage/pages/ResetPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('LoginPage').path,
      name: 'LoginPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/LoginPage/reducer'),
          import('containers/LoginPage/sagas'),
          import('new/modules/LoginPage/pages/LoginPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules
          .then(([reducer, sagas, component]) => {
            injectReducer('loginPage', reducer.default);
            injectSagas(sagas.default);
            renderRoute(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('ManageLocations').path,
      name: 'Manage Locations',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/ManageLocations/pages/ManageLocationsPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('ManageLocationsEdit').path,
      name: 'Manage Locations Edit',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/ManageLocations/pages/ManageLocationsEditPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('ManageUsers').path,
      name: 'Manage Users',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/ManageUsers/pages/ManageUsersPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('ManagePlans').path,
      name: 'Manage Plans',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/ManagePlans/pages/ManagePlansPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('ManageGroups').path,
      name: 'Manage Groups',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/ManageGroups/pages/ManageGroups'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('MultiLocationBilling').path,
      name: 'Multi-location Billing',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/MultiLocationBilling/pages/MultiLocationBillingPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('MultiLocationReports').path,
      name: 'Multi-location Reports',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/MultiLocationReports/pages/MultiLocationReportsPage'),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('OrderInfoCards').path,
      name: 'Order Info Cards',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/OrderInfoCards/pages/OrderInfoCardsPage'),
        ]);
        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('PlanBuilderPage').path,
      name: 'Plan Builder',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/PlanBuilder/pages/PlanBuilderPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('SeasonOfGivingPage').path,
      name: 'Season Of Giving',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/SeasonOfGiving/pages/SeasonOfGivingPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('LoginPage').path,
      name: 'Login',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/LoginPage/pages/LoginPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('ForgotPage').path,
      name: 'Forgot Password',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/LoginPage/pages/ForgotPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('ResetPage').path,
      name: 'Reset Password',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/LoginPage/pages/ResetPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('UpgradePlan').path,
      name: 'Upgrade Plan',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/UpgradePlan/pages/UpgradePlanPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('PatientReferralPage').path,
      name: 'Patient Refferal',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/Referral/pages/PatientReferralPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: findRoute('OfficeReferralPage').path,
      name: 'Office Referral',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/Referral/pages/OfficeReferralPage'
          ),
        ]);

        importModules
          .then(([component]) => {
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    //////////////////////////////////////////////////////////////////////////////////////
    //// all new pages must go above this line
    //////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/',
      onEnter: (nextState, replace) => {
        replace('/accounts/login');
      },
      // path: '/',
      // name: 'home',
      // getComponent(nextState, cb) {
      //   Promise.all([import('containers/HomePage')])
      //     .then(([component]) => {
      //       loadModule(cb)(component);
      //     })
      //     .catch(errorLoading);
      // },
    },

    {
      path: '/community-enroll/:groupCode',
      name: 'signupPatientGroupCodePage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/SignupPatientSlugPage/reducer'),
          import('containers/SignupPatientSlugPage/sagas'),
          import('containers/SignupPatientSlugPage'),
        ])
          .then(([reducer, sagas, component]) => {
            injectReducer('signupOfficeSlugPage', reducer.default);
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: '/signup/:officeSlug',
      name: 'signupOfficeSlugPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/SignupPatientSlugPage/reducer'),
          import('containers/SignupPatientSlugPage/sagas'),
          import('containers/SignupPatientSlugPage'),
        ])
          .then(([reducer, sagas, component]) => {
            injectReducer('signupOfficeSlugPage', reducer.default);
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: '/start',
      name: 'signupStartPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/SignupStartPage/reducer'),
          import('containers/SignupStartPage/sagas'),
          import('containers/SignupStartPage'),
        ])
          .then(([reducer, sagas, component]) => {
            injectReducer('signupStartPage', reducer.default);
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: '/businessconnect/:officeSlug',
      name: 'businessConnectEmployerLandingPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/SignupPatientSlugPage/reducer'),
          import('containers/SignupPatientSlugPage/sagas'),
          import('containers/BusinessConnectEmployerLandingPage'),
        ])
          .then(([reducer, sagas, component]) => {
            injectReducer('signupOfficeSlugPage', reducer.default);
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToDashboard,
      path: '/accounts/set-password/:token',
      name: 'passwordResetPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/PasswordResetPage/sagas'),
          import('containers/PasswordResetPage'),
        ])
          .then(([sagas, component]) => {
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: '/accounts/old-dentist-signup',
      name: 'dentistSignupPageNew',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/App/sagas'),

          import('containers/LearnMorePage/reducer'),
          import('containers/LearnMorePage/sagas'),

          import('containers/DentistSignupPageNew/reducer'),
          import('containers/DentistSignupPageNew/sagas'),
          import('containers/DentistSignupPageNew'),
        ])
          .then(
            ([
              appSagas,
              learnMoreReducers,
              learnMoreSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectSagas(appSagas.default);

              injectReducer('learnMorePage', learnMoreReducers.default);
              injectSagas(learnMoreSagas.default);

              injectReducer('dentistSignupPageNew', reducer.default);
              injectSagas(sagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
    },
    {
      path: '/accounts/dentist-signup',
      name: 'dentistSignupPageNew',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/App/sagas'),

          import('containers/LearnMorePage/reducer'),
          import('containers/LearnMorePage/sagas'),

          import('containers/DentistSignupPageTemp/reducer'),
          import('containers/DentistSignupPageTemp/sagas'),
          import('containers/DentistSignupPageTemp'),
        ])
          .then(
            ([
              appSagas,
              learnMoreReducers,
              learnMoreSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectSagas(appSagas.default);

              injectReducer('learnMorePage', learnMoreReducers.default);
              injectSagas(learnMoreSagas.default);

              injectReducer('dentistSignupPageTemp', reducer.default);
              injectSagas(sagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
    },
    {
      path: '/accounts/small-business-signup',
      name: 'smBusinessSignUp',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/App/sagas'),

          import('containers/SmBusinessSignupPage/reducer'),
          import('containers/SmBusinessSignupPage/sagas'),
          import('containers/SmBusinessSignupPage'),
        ])
          .then(([appSagas, reducer, sagas, component]) => {
            injectSagas(appSagas.default);

            injectReducer('smBusinessSignUp', reducer.default);
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: '/accounts/community-signup',
      name: 'communitySignUp',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/App/sagas'),

          import('containers/SmBusinessSignupPage/reducer'),
          import('containers/SmBusinessSignupPage/sagas'),
          import('containers/SmBusinessSignupPage'),
        ])
          .then(([appSagas, reducer, sagas, component]) => {
            injectSagas(appSagas.default);

            injectReducer('smBusinessSignUp', reducer.default);
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: '/accounts/complete-dentist-signup/:dentistInfoId/:activationKey',
      name: 'completeDentistSignupPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/App/sagas'),

          import('containers/CompleteDentistSignupPage/reducer'),
          import('containers/CompleteDentistSignupPage/sagas'),
          import('containers/CompleteDentistSignupPage'),
        ])
          .then(([appSagas, reducer, sagas, component]) => {
            injectSagas(appSagas.default);

            injectReducer('completeDentistSignupPage', reducer.default);
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: '/accounts/complete-business-signup/:smBusinessInfoId/:activationKey',
      name: 'completeBusinessSignupPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/App/sagas'),
          import('containers/CompleteBusinessSignupPage/reducer'),
          import('containers/CompleteBusinessSignupPage/sagas'),
          import('containers/CompleteBusinessSignupPage'),
        ])
          .then(([appSagas, reducer, sagas, component]) => {
            injectSagas(appSagas.default);

            injectReducer('completeBusinessSignupPage', reducer.default);
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      path: '/accounts/signup/my-optometrist/:dentistInfoId',
      name: 'signupOptometristPatientRegformPage',
      getComponent(nextState, cb) {
         const importModules = Promise.all([
          import(
            'new/modules/ExternalPatientSignup/pages/SignupLandingPage'
          ),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/accounts/signup/my-dentist/:dentistInfoId',
      name: 'signupPatientRegformPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import(
            'new/modules/ExternalPatientSignup/pages/SignupLandingPage'
          ),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
        
      },
    },
    // fix for bad qr codes
    {
      onEnter: redirectToSignup,
      path: '//accounts/signup/my-dentist/:dentistInfoId',
      name: 'signupPatientRegformPage',
    },
    {
      onEnter: redirectToSignup,
      path: '//accounts/signup/my-optometrist/:dentistInfoId',
      name: 'signupOptometristPatientRegformPage',
    },
    {
      path: '/reenroll/my-optometrist/:dentistInfoId',
      name: 'reSignupPatientRegformPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/dashboardUpdate/SignupPatientRegformPage/reducer'),
          import('containers/dashboardUpdate/SignupPatientRegformPage/sagas'),
          import('containers/ReSignupPatientRegformPage/reducer'),
          import('containers/ReSignupPatientRegformPage/sagas'),
          import('containers/ReSignupPatientRegformPage'),
        ])
          .then(
            ([
              dashboardReducers,
              dashboardSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectReducer(
                'dashboardSignupPatientRegformPage',
                dashboardReducers.default,
              );
              injectSagas(dashboardSagas.default);

              injectReducer('reSignupPatientRegformPage', reducer.default);
              injectSagas(sagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
    },
    {
      path: '/reenroll/my-dentist/:dentistInfoId',
      name: 'reSignupPatientRegformPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/dashboardUpdate/SignupPatientRegformPage/reducer'),
          import('containers/dashboardUpdate/SignupPatientRegformPage/sagas'),
          import('containers/ReSignupPatientRegformPage/reducer'),
          import('containers/ReSignupPatientRegformPage/sagas'),
          import('containers/ReSignupPatientRegformPage'),
        ])
          .then(
            ([
              dashboardReducers,
              dashboardSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectReducer(
                'dashboardSignupPatientRegformPage',
                dashboardReducers.default,
              );
              injectSagas(dashboardSagas.default);

              injectReducer('reSignupPatientRegformPage', reducer.default);
              injectSagas(sagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
    },
    // {
    //   path: '/accounts/ncds-signup',
    //   onEnter: (nextState, replace) => {
    //     replace('/enroll/practice?promo=NCDS2022');
    //   },
    // },
    // {
    //   path: '/accounts/oh-signup',
    //   onEnter: (nextState, replace) => {
    //     replace('/enroll/practice?promo=ODA2022');
    //   },
    // },
    // {
    //   path: '/accounts/ok-signup',
    //   onEnter: (nextState, replace) => {
    //     replace('/enroll/practice?promo=OKDA2022');
    //   },
    // },
    // {
    //   path: '/accounts/gargle-signup',
    //   onEnter: (nextState, replace) => {
    //     replace('/enroll/practice?promo=GARGLE2022');
    //   },
    // },
    // {
    //   path: '/accounts/elite-dental-alliance-signup',
    //   onEnter: (nextState, replace) => {
    //     replace('/enroll/practice?promo=ELITE2022');
    //   },
    // },
    // {
    //   path: '/accounts/smile-source-signup',
    //   onEnter: (nextState, replace) => {
    //     replace('/enroll/practice?promo=SMILESOURCE2022');
    //   },
    // },
    {
      path: '/accounts/logout',
      onEnter: (nextState, replace) => {
        replace('/accounts/login');
      },
      // path: '/',
      // name: 'home',
      // getComponent(nextState, cb) {
      //   Promise.all([import('containers/HomePage')])
      //     .then(([component]) => {
      //       loadModule(cb)(component);
      //     })
      //     .catch(errorLoading);
      // },
    },
    {
      path: '/accounts/:associationUrl',
      onEnter: (nextState, replace) => {
        replace('/enroll/practice?promo=133D23PARTNER');
      },
      // name: 'NCDSdentistSignupPage',
      // getComponent(nextState, cb) {
      //   Promise.all([
      //     import('containers/App/sagas'),

      //     import('containers/LearnMorePage/reducer'),
      //     import('containers/LearnMorePage/sagas'),

      //     import('containers/DentistSignupPageTemp/reducer'),
      //     import('containers/DentistSignupPageTemp/sagas'),
      //     import('containers/DentistSignupPageTemp'),
      //   ])
      //     .then(
      //       ([
      //         appSagas,
      //         learnMoreReducers,
      //         learnMoreSagas,
      //         reducer,
      //         sagas,
      //         component,
      //       ]) => {
      //         injectSagas(appSagas.default);

      //         injectReducer('learnMorePage', learnMoreReducers.default);
      //         injectSagas(learnMoreSagas.default);

      //         injectReducer('dentistSignupPageTemp', reducer.default);
      //         injectSagas(sagas.default);
      //         loadModule(cb)(component);
      //       },
      //     )
      //     .catch(errorLoading);
      // },
      //   getComponent(nextState, cb) {
      //     Promise.all([
      //       import('containers/App/sagas'),

      //       import('containers/LearnMorePage/reducer'),
      //       import('containers/LearnMorePage/sagas'),

      //       import('containers/AssociationDentistSignupPage/reducer'),
      //       import('containers/AssociationDentistSignupPage/sagas'),
      //       import('containers/AssociationDentistSignupPage'),
      //     ])
      //       .then(
      //         ([
      //           appSagas,
      //           learnMoreReducers,
      //           learnMoreSagas,
      //           reducer,
      //           sagas,
      //           component,
      //         ]) => {
      //           injectSagas(appSagas.default);

      //           injectReducer('learnMorePage', learnMoreReducers.default);
      //           injectSagas(learnMoreSagas.default);

      //           injectReducer('AssociationdentistSignupPage', reducer.default);
      //           injectSagas(sagas.default);
      //           loadModule(cb)(component);
      //         },
      //       )
      //       .catch(errorLoading);
      //   },
    },
    {
      path: '/signup-complete',
      name: 'signupCompletePage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/SignupCompletePage/sagas'),
          import('containers/SignupCompletePage'),
        ])
          .then(([sagas, component]) => {
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/dentist-users/:dentistInfoId',
      name: 'adminDentistUsersPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),
          import('containers/dashboardUpdate/AdminDentistUsersPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([accountingReducer, accountingSagas, adminmembershipsPageReducers, adminmembershipsPageSagas,component]) => {
            injectReducer('dentistMembersPage', accountingReducer.default);
            injectSagas(accountingSagas.default);
            injectReducer(
              'adminDentistsPage',
              adminmembershipsPageReducers.default,
            );
            injectSagas(adminmembershipsPageSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/terms-update',
      name: 'termsUpdate',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/TermsUpdatePage'),
        ]);
        const renderRoute = loadModule(cb);

        importModules.then(
          ([accountingReducer, accountingSagas, component]) => {
            injectReducer('dentistMembersPage', accountingReducer.default);
            injectSagas(accountingSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/payment-history/patient/:patientId',
      name: 'adminPatientAccountingPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AccountingModule/reducer'),
          import('containers/AccountingModule/sagas'),
          import('containers/AdminAccountingPage/patient'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([accountingReducer, accountingSagas, component]) => {
            injectReducer('accountingReducer', accountingReducer.default);
            injectSagas(accountingSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/dentist-history/:dentistInfoId',
      name: 'adminPatientAccountingPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AccountingModule/reducer'),
          import('containers/AccountingModule/sagas'),
          import('containers/AdminAccountingPage/dentistLogs'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([accountingReducer, accountingSagas, component]) => {
            injectReducer('accountingReducer', accountingReducer.default);
            injectSagas(accountingSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/errorLogs',
      name: 'adminErrorLogsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AccountingModule/reducer'),
          import('containers/AccountingModule/sagas'),
          import('containers/AdminAccountingPage/errorLogs'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([accountingReducer, accountingSagas, component]) => {
            injectReducer('accountingReducer', accountingReducer.default);
            injectSagas(accountingSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/leadSource',
      name: 'adminLeadSourcePage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminManagePage/reducer'),
          import('containers/AdminManagePage/sagas'),
          import('containers/AdminLeadSourcePage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([accountingReducer, accountingSagas, component]) => {
            injectReducer('AdminManagePage', accountingReducer.default);
            injectSagas(accountingSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/membership-history/patient/:patientId',
      name: 'adminPatientMembershipLogPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AccountingModule/reducer'),
          import('containers/AccountingModule/sagas'),
          import('containers/AdminAccountingPage/membershipLog'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([accountingReducer, accountingSagas, component]) => {
            injectReducer('accountingReducer', accountingReducer.default);
            injectSagas(accountingSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/membership-history/patient/:patientId',
      name: 'patientMembershipLogPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AccountingModule/reducer'),
          import('containers/AccountingModule/sagas'),
          import(
            'containers/dashboardUpdate/DentistAccountingPageOld/membershipLog'
          ),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([accountingReducer, accountingSagas, component]) => {
            injectReducer('accountingReducer', accountingReducer.default);
            injectSagas(accountingSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/dentists',
      name: 'adminDentistsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),
          import('containers/AdminDentistsPage'),

          //import('containers/AdminSuperAdmin/reducers'),
          //import('containers/AdminSuperAdmin/sagas'),
        ]);

        const renderRoute = loadModule(cb);

        importModules
          .then(([adminDentistsReducers, adminDentistsSagas, component]) => {
            injectReducer('adminDentistsPage', adminDentistsReducers.default);
            injectSagas(adminDentistsSagas.default);

            //injectReducer('adminsuperadmin', reducer.default);
            //injectSagas(sagas.default);
            loadModule(cb)(component);
          })

          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/members',
      name: 'adminMembersPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminSuperAdmin/reducers'),
          import('containers/AdminSuperAdmin/sagas'),
          import('containers/dashboardUpdate/MembershipsPage/reducers'),
          import('containers/dashboardUpdate/MembershipsPage/sagas'),
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),
          import('containers/AdminMembersPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules
          .then(
            ([
              adminSuperReducers,
              adminSuperSagas,
              adminmembershipsPageReducers,
              adminmembershipsPageSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectReducer('AdminSuperAdminPage', adminSuperReducers.default);
              injectSagas(adminSuperSagas.default);
              injectReducer(
                'dentistMembershipsPage',
                adminmembershipsPageReducers.default,
              );
              injectSagas(adminmembershipsPageSagas.default);

              injectReducer('adminDentistsPage', reducer.default);
              injectSagas(sagas.default);
              renderRoute(component);
            },
          )

          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/reports',
      name: 'adminReportsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),

          import('containers/AdminReportsPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([adminDentistsReducer, adminDentistsSagas, component]) => {
            injectReducer('adminDentistsPage', adminDentistsReducer.default);
            injectSagas(adminDentistsSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/reporting',
      name: 'adminReportsBuilderPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminReportBuilder/reducer'),
          import('containers/AdminReportBuilder/sagas'),

          import('containers/AdminReportBuilder'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([adminDentistsReducer, adminDentistsSagas, component]) => {
            injectReducer(
              'adminReportsBuilderPage',
              adminDentistsReducer.default,
            );
            injectSagas(adminDentistsSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/kpi',
      name: 'adminKPIPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),
          import('containers/AdminKPI/reducer'),
          import('containers/AdminKPI/sagas'),
          import('containers/AdminKPI'),
        ]);

        const renderRoute = loadModule(cb);

        importModules
          .then(
            ([
              adminDentistsReducers,
              adminDentistsSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectReducer('adminDentistsPage', adminDentistsReducers.default);
              injectSagas(adminDentistsSagas.default);

              injectReducer('adminKPIPage', reducer.default);
              injectSagas(sagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
      // }, {
      //   onEnter: redirectToLogin,
      //   path: '/admin/reviews',
      //   name: 'adminReviewsPage',
      //   getComponent(nextState, cb) {
      //     const importModules = Promise.all([
      //       import('containers/AdminDentistsPage/reducer'),
      //       import('containers/AdminDentistsPage/sagas'),

      //       import('containers/AdminReviewsPage'),
      //     ]);

      //     const renderRoute = loadModule(cb);

      //     importModules.then(([
      //       adminDentistsReducer,
      //       adminDentistsSagas,

      //       component
      //     ]) => {
      //       injectReducer('adminDentistsPage', adminDentistsReducer.default);
      //       injectSagas(adminDentistsSagas.default);

      //       renderRoute(component);
      //     });

      //     importModules.catch(errorLoading);
      //   },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/manage',
      name: 'adminManagePage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminManagePage/reducer'),
          import('containers/AdminManagePage/sagas'),
          import('containers/AdminManagePage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('AdminManagePage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/admin/smsSetup',
      name: 'AdminSmsSetup',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminSmsSetup'),
        ]);
        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
        importModules.catch(errorLoading);
      },
    },
    {
      path: '/admin/smsLogs',
      name: 'AdminSmsLogs',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminSmsLogs'),
        ]);
        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/associations',
      name: 'adminAssociationsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),

          import('containers/AdminAssociationsPage/reducer'),
          import('containers/AdminAssociationsPage/sagas'),
          import('containers/AdminAssociationsPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            dentistMembersReducers,
            dentistMembersSagas,
            adminDentistsReducers,
            adminDentistsSagas,
            reducer,
            sagas,
            component,
          ]) => {
            injectReducer('dentistMembersPage', dentistMembersReducers.default);
            injectSagas(dentistMembersSagas.default);
            injectReducer('adminDentistsPage', adminDentistsReducers.default);
            injectSagas(adminDentistsSagas.default);
            injectReducer('adminAssociationsPage', reducer.default);
            injectSagas(sagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/business-connect',
      name: 'adminBusinessConnectPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/AdminBusinessConnectPage/reducer'),
          import('containers/AdminBusinessConnectPage/sagas'),
          import('containers/AdminBusinessConnectPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            dentistMembersReducers,
            dentistMembersSagas,
            reducer,
            sagas,
            component,
          ]) => {
            injectReducer('dentistMembersPage', dentistMembersReducers.default);
            injectSagas(dentistMembersSagas.default);
            injectReducer('adminBusinessConnectPage', reducer.default);
            injectSagas(sagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/super_admin',
      name: 'adminSuperAdminPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),

          import('containers/dashboardUpdate/MembershipsPage/reducers'),
          import('containers/dashboardUpdate/MembershipsPage/sagas'),
          import('containers/AdminSuperAdmin/reducers'),
          import('containers/AdminSuperAdmin/sagas'),
          import('containers/AdminSuperAdmin'),
        ]);

        importModules.then(
          ([
            adminDentistsReducers,
            adminDentistsSagas,
            adminmembershipsPageReducers,
            adminmembershipsPageSagas,
            reducer,
            sagas,
            component,
          ]) => {
            injectReducer('adminDentistsPage', adminDentistsReducers.default);
            injectSagas(adminDentistsSagas.default);
            injectReducer(
              'dentistMembershipsPage',
              adminmembershipsPageReducers.default,
            );
            injectSagas(adminmembershipsPageSagas.default);

            injectReducer('AdminSuperAdminPage', reducer.default);
            injectSagas(sagas.default);
            loadModule(cb)(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/more',
      name: 'adminAlmostSuperAdminPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),

          import('containers/dashboardUpdate/MembershipsPage/reducers'),
          import('containers/dashboardUpdate/MembershipsPage/sagas'),

          import('containers/AdminSuperAdmin/reducers'),
          import('containers/AdminSuperAdmin/sagas'),
          import('containers/AdminAlmostSuperAdmin'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            adminDentistsReducers,
            adminDentistsSagas,
            adminmembershipsPageReducers,
            adminmembershipsPageSagas,
            reducer,
            sagas,
            component,
          ]) => {
            injectReducer('adminDentistsPage', adminDentistsReducers.default);
            injectSagas(adminDentistsSagas.default);
            injectReducer(
              'dentistMembershipsPage',
              adminmembershipsPageReducers.default,
            );
            injectSagas(adminmembershipsPageSagas.default);

            injectReducer('AdminAlmostSuperAdminPage', reducer.default);
            injectSagas(sagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/fees',
      name: 'AdminManagementFee',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),

          import('containers/AdminManagementFee'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([adminDentistsReducers, adminDentistsSagas, component]) => {
            injectReducer('adminDentistsPage', adminDentistsReducers.default);
            injectSagas(adminDentistsSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/admin/promos',
      name: 'AdminPromos',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),

          import('containers/AdminPromos'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([adminDentistsReducers, adminDentistsSagas, component]) => {
            injectReducer('adminDentistsPage', adminDentistsReducers.default);
            injectSagas(adminDentistsSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
      // }, {
      //   onEnter: redirectToLogin,
      //   path: '/admin/services',
      //   name: 'adminServicesPage',
      //   getComponent(nextState, cb) {
      //     const importModules = Promise.all([
      //       import('containers/AdminManagePage/reducer'),
      //       import('containers/AdminManagePage/sagas'),
      //       import('containers/AdminServicesPage'),
      //     ]);

      //     const renderRoute = loadModule(cb);

      //     importModules.then(([reducer, sagas, component]) => {
      //       injectReducer('AdminManagePage', reducer.default);
      //       injectSagas(sagas.default);
      //       renderRoute(component);
      //     });

      //     importModules.catch(errorLoading);
      //   },
      // }, {
      //   onEnter: redirectToLogin,
      //   path: '/admin/promo_codes',
      //   name: 'adminPromosPage',
      //   getComponent(nextState, cb) {
      //     const importModules = Promise.all([
      //       import('containers/AdminManagePage/reducer'),
      //       import('containers/AdminManagePage/sagas'),
      //       import('containers/AdminPromosPage'),
      //     ]);

      //     const renderRoute = loadModule(cb);

      //     importModules.then(([reducer, sagas, component]) => {
      //       injectReducer('AdminManagePage', reducer.default);
      //       injectSagas(sagas.default);
      //       renderRoute(component);
      //     });

      //     importModules.catch(errorLoading);
      //   },
    },
    {
      onEnter: redirectToLogin,
      path: '/associations/dentists',
      name: 'associationsDentistsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AdminDentistsPage/reducer'),
          import('containers/AdminDentistsPage/sagas'),
          import('containers/AssociationsDentistsPage/reducer'),
          import('containers/AssociationsDentistsPage/sagas'),
          import('containers/AssociationsDentistsPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            AdminDentistsReducer,
            AdminDentistsSagas,
            associationDentistsReducer,
            associationDentistsSagas,
            component,
          ]) => {
            injectReducer('adminDentistsPage', AdminDentistsReducer.default);
            injectSagas(AdminDentistsSagas.default);
            injectReducer(
              'associationsDentistsPage',
              associationDentistsReducer.default,
            );
            injectSagas(associationDentistsSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/associations/reporting',
      name: 'associationsReportsBuilderPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/AssociationsReportBuilder/reducer'),
          import('containers/AssociationsReportBuilder/sagas'),

          import('containers/AssociationsReportBuilder'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([adminDentistsReducer, adminDentistsSagas, component]) => {
            injectReducer(
              'associationsReportsBuilderPage',
              adminDentistsReducer.default,
            );
            injectSagas(adminDentistsSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
      // }, {
      //   path: '/charge15',
      //   name: 'chargePage15',
      //   getComponent(nextState, cb) {
      //     Promise.all([
      //       import('containers/ChargePage15'),
      //     ])
      //       .then(([component]) => {
      //         loadModule(cb)(component);
      //       })
      //       .catch(errorLoading);
      //   },
      // }, {
      //   path: '/charge20',
      //   name: 'chargePage20',
      //   getComponent(nextState, cb) {
      //     Promise.all([
      //       import('containers/ChargePage20'),
      //     ])
      //       .then(([component]) => {
      //         loadModule(cb)(component);
      //       })
      //       .catch(errorLoading);
      //   },
      // }, {
      //   // TODO: DELETE this page
      //   // NOTE: must create the dentist dashboard first
      //   //       search for `/dentist-dashboard` to see where to link to it
      //   onEnter: redirectToLogin,
      //   path: '/dashboard',
      //   name: 'dashboard',
      //   getComponent(nextState, cb) {
      //     const importModules = Promise.all([
      //       import('containers/Dashboard'),
      //     ]);

      //     const renderRoute = loadModule(cb);

      //     importModules.then(([component]) => {
      //       renderRoute(component);
      //     });

      //     importModules.catch(errorLoading);
      //   },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist/faq',
      name: 'dentistFaq',
      getComponent(nextState, cb) {
        import('containers/FaqPage/dentist-faq')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/business-faq',
      name: 'businessFaq',
      getComponent(nextState, cb) {
        import('containers/FaqPage/business-faq')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/payment-ledger',
      name: 'dentistAccountingPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/AccountingModule/reducer'),
          import('containers/dashboardUpdate/AccountingModule/sagas'),
          import('containers/dashboardUpdate/DentistAccountingPageOld'),
        ])
          .then(
            ([
              dentistMembersReducers,
              dentistMembersSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectReducer(
                'dentistMembersPage',
                dentistMembersReducers.default,
              );
              injectSagas(dentistMembersSagas.default);

              injectReducer('accountingReducer', reducer.default);
              injectSagas(sagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/activity',
      name: 'dentistAccountingPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/AccountingModule/reducer'),
          import('containers/dashboardUpdate/AccountingModule/sagas'),
          import('containers/dashboardUpdate/DentistAccountingPage'),
        ])
          .then(
            ([
              dentistMembersReducers,
              dentistMembersSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectReducer(
                'dentistMembersPage',
                dentistMembersReducers.default,
              );
              injectSagas(dentistMembersSagas.default);

              injectReducer('accountingReducer', reducer.default);
              injectSagas(sagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/payment-history/:patientId',
      name: 'dentistPatientAccountingPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/AccountingModule/reducer'),
          import('containers/dashboardUpdate/AccountingModule/sagas'),
          import('containers/dashboardUpdate/DentistAccountingPageOld/patient'),
        ])
          .then(
            ([
              dentistMembersReducers,
              dentistMembersSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectReducer(
                'dentistMembersPage',
                dentistMembersReducers.default,
              );
              injectSagas(dentistMembersSagas.default);

              injectReducer('accountingReducer', reducer.default);
              injectSagas(sagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
    },
    {
      path: '/dentist-new/contact-admin',
      name: 'contactAdminPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/ContactAdminPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: findRoute('UpgradePage').path,
      name: 'upgradePage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/UpgradePage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: findRoute('UpgradePageForm').path,
      name: 'upgradeFormPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/UpgradeFormPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/dentist-new/marketing',
      name: 'marketingPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/MarketingPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/dentist-new/signup',
      name: 'dashboardSignupPatientRegformPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/SignupPatientRegformPage/reducer'),
          import('containers/dashboardUpdate/SignupPatientRegformPage/sagas'),
          import('containers/dashboardUpdate/SignupPatientRegformPage'),
        ])
          .then(
            ([
              dentistMembersReducers,
              dentistMembersSagas,
              dashboardReducers,
              dashboardSagas,
              component,
            ]) => {
              injectReducer(
                'dentistMembersPage',
                dentistMembersReducers.default,
              );

              injectSagas(dentistMembersSagas.default);

              injectReducer(
                'dashboardSignupPatientRegformPage',
                dashboardReducers.default,
              );
              injectSagas(dashboardSagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
    },
    {
      path: '/dentist-new/faq',
      name: 'faqDashboardPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/FaqDashboardPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/dentist-new/profile',
      name: 'userProfilePage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/UserProfilePage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/edit-profile',
      name: 'dentistEditProfilePage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/DentistEditProfilePage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/get-started',
      name: 'dentistGetStartedPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/DentistGetStartedPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/BAA-signed',
      name: 'agreementBAAComplete',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/BAA-signed'),
        ]);
        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });
        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/reports',
      name: 'dentistReportsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/AccountingModule/reducer'),
          import('containers/dashboardUpdate/AccountingModule/sagas'),
          import('containers/dashboardUpdate/DentistReportsPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            dentistMembersReducer,
            dentistMembersSagas,
            accountingReducer,
            accountingSagas,
            component,
          ]) => {
            injectReducer('accountingReducer', accountingReducer.default);
            injectSagas(accountingSagas.default);
            injectReducer('dentistMembersPage', dentistMembersReducer.default);
            injectSagas(dentistMembersSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      // year:  YYYY
      // month: MM
      path: '/dentist-new/web-report/:year/:month',
      name: 'dentistReportsPageWebview',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/AccountingModule/reducer'),
          import('containers/dashboardUpdate/AccountingModule/sagas'),
          import('containers/dashboardUpdate/DentistReportsPageWebview'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            dentistMembersReducer,
            dentistMembersSagas,
            accountingReducer,
            accountingSagas,
            component,
          ]) => {
            injectReducer('accountingReducer', accountingReducer.default);
            injectSagas(accountingSagas.default);
            injectReducer('dentistMembersPage', dentistMembersReducer.default);
            injectSagas(dentistMembersSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/web-payout/:payoutURL',
      name: 'dentistPayoutsPageWebview',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/AccountingModule/reducer'),
          import('containers/dashboardUpdate/AccountingModule/sagas'),
          import('containers/dashboardUpdate/DentistPayoutsPageWebview'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            dentistMembersReducer,
            dentistMembersSagas,
            accountingReducer,
            accountingSagas,
            component,
          ]) => {
            injectReducer('accountingReducer', accountingReducer.default);
            injectSagas(accountingSagas.default);
            injectReducer('dentistMembersPage', dentistMembersReducer.default);
            injectSagas(dentistMembersSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/users',
      name: 'dentistUsersPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/DentistUsersPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/members',
      name: 'dentistMembersPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/DentistMembersPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/business-connect',
      name: 'dentistBusinessConnectPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import(
            'containers/dashboardUpdate/DentistBusinessConnectPage/reducer'
          ),
          import('containers/dashboardUpdate/DentistBusinessConnectPage/sagas'),
          import('containers/dashboardUpdate/DentistBusinessConnectPage'),
        ]);
        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            dentistMembersReducers,
            dentistMembersSagas,
            reducer,
            sagas,
            component,
          ]) => {
            injectReducer('dentistMembersPage', dentistMembersReducers.default);
            injectSagas(dentistMembersSagas.default);
            injectReducer('dentistBusinessConnectPage', reducer.default);
            injectSagas(sagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/landing-connect',
      name: 'dentistBusinessConnectLandingPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/BusinessConnectLandingPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/video-connect',
      name: 'dentistBusinessConnectVideoPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/BusinessConnectVideoPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/community-connect',
      name: 'dentistCommunityConnectPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import(
            'containers/dashboardUpdate/DentistBusinessConnectPage/reducer'
          ),
          import('containers/dashboardUpdate/DentistBusinessConnectPage/sagas'),
          import('containers/dashboardUpdate/DentistCommunityConnectPage'),
        ]);
        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            dentistMembersReducers,
            dentistMembersSagas,
            reducer,
            sagas,
            component,
          ]) => {
            injectReducer('dentistMembersPage', dentistMembersReducers.default);
            injectSagas(dentistMembersSagas.default);
            injectReducer('dentistBusinessConnectPage', reducer.default);
            injectSagas(sagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/landing-community-connect',
      name: 'dentistBusinessConnectLandingPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/BusinessConnectLandingPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('dentistMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/business-connect-reports',
      name: 'dentistBusinessConnectReports',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/BusinessConnectReports/reducer'),
          import('containers/dashboardUpdate/BusinessConnectReports/sagas'),
          import('containers/dashboardUpdate/BusinessConnectReports'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            dentistMembersReducers,
            dentistMembersSagas,
            reducer,
            sagas,
            component,
          ]) => {
            injectReducer('dentistMembersPage', dentistMembersReducers.default);
            injectSagas(dentistMembersSagas.default);
            injectReducer('dentistBusinessConnectReports', reducer.default);
            injectSagas(sagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/dentist-new/memberships',
      name: 'dentistMembershipsPage',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),
          import('containers/dashboardUpdate/MembershipsPage/reducers'),
          import('containers/dashboardUpdate/MembershipsPage/sagas'),
          import('containers/dashboardUpdate/MembershipsPage'),
        ])
          .then(
            ([
              dentistMembersReducers,
              dentistMembersSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectReducer(
                'dentistMembersPage',
                dentistMembersReducers.default,
              );
              injectSagas(dentistMembersSagas.default);

              injectReducer('dentistMembershipsPage', reducer.default);
              injectSagas(sagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
    },
    {
      //onEnter: redirectToLogin,
      path: '/business/members',
      name: 'businessMembersPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/businessDashboard/BusinessMembersPage/reducer'),
          import('containers/businessDashboard/BusinessMembersPage/sagas'),
          import('containers/businessDashboard/BusinessMembersPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('businessMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/business/settings',
      name: 'businessSetting',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/businessDashboard/BusinessMembersPage/reducer'),
          import('containers/businessDashboard/BusinessMembersPage/sagas'),
          import('containers/businessDashboard/BusinessSetting/reducer'),
          import('containers/businessDashboard/BusinessSetting/sagas'),
          import('containers/businessDashboard/BusinessSetting'),
        ])
          .then(
            ([
              businessMembersReducers,
              businessMembersSagas,
              reducer,
              sagas,
              component,
            ]) => {
              injectReducer(
                'businessMembersPage',
                businessMembersReducers.default,
              );
              injectSagas(businessMembersSagas.default);

              injectReducer('businessSetting', reducer.default);
              injectSagas(sagas.default);
              loadModule(cb)(component);
            },
          )
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/business/reports',
      name: 'businessReports',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/businessDashboard/BusinessReports/reducer'),
          import('containers/businessDashboard/BusinessReports/sagas'),
          import('containers/businessDashboard/BusinessReports'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('businessMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/business/contact-admin',
      name: 'contactAdminPageBusiness',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/businessDashboard/BusinessMembersPage/reducer'),
          import('containers/businessDashboard/BusinessMembersPage/sagas'),
          import('containers/businessDashboard/ContactAdminPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('businessMembersPage', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/error/404-not-found',
      name: 'notFoundPage',
      getComponent(nextState, cb) {
        import('containers/NotFoundPage')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      path: '/faq',
      name: 'faq',
      getComponent(nextState, cb) {
        import('containers/FaqPage').then(loadModule(cb)).catch(errorLoading);
      },
      // }, {
      //   path: '/learn-more',
      //   name: 'learnMore',
      //   getComponent(nextState, cb) {
      //     const importModules = Promise.all([
      //       import('containers/LearnMorePage/reducer'),
      //       import('containers/LearnMorePage/sagas'),
      //       import('containers/LearnMorePage'),
      //     ]);

      //     const renderRoute = loadModule(cb);

      //     importModules.then(([ reducer, sagas, component ]) => {
      //       injectReducer('learnMorePage', reducer.default);
      //       injectSagas(sagas.default);
      //       renderRoute(component);
      //     });

      //     importModules.catch(errorLoading);
      //   },
    },
    {
      // onEnter: redirectToDashboard,
      path: '/marketplace/profile/:dentistInfoId',
      name: 'marketplaceProfile',
      getComponent(nextState, cb) {
        Promise.all([
          import('containers/MarketplaceProfilePage/reducers'),
          import('containers/MarketplaceProfilePage/sagas'),
          import('containers/MarketplaceProfilePage'),
        ])
          .then(([reducer, sagas, component]) => {
            injectReducer('marketPlaceProfile', reducer.default);
            injectSagas(sagas.default);
            loadModule(cb)(component);
          })
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/patient/payment-history',
      name: 'patientAccountingPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/PatientProfilePage/reducer'),
          import('containers/PatientProfilePage/sagas'),

          import('containers/dashboardUpdate/DentistMembersPage/reducer'),
          import('containers/dashboardUpdate/DentistMembersPage/sagas'),

          import('containers/dashboardUpdate/AccountingModule/reducer'),
          import('containers/dashboardUpdate/AccountingModule/sagas'),
          import('containers/PatientAccountingPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([
            patientDashboardReducer,
            patientDashboardSagas,

            dentistMembersReducers,
            dentistMembersSagas,

            accountingReducer,
            accountingSagas,

            component,
          ]) => {
            injectReducer(
              'patientProfilePage',
              patientDashboardReducer.default,
            );
            injectSagas(patientDashboardSagas.default);

            injectReducer('dentistMembersPage', dentistMembersReducers.default);
            injectSagas(dentistMembersSagas.default);

            injectReducer('accountingReducer', accountingReducer.default);
            injectSagas(accountingSagas.default);

            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/patient/profile',
      name: 'patientProfilePage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/PatientProfilePage/reducer'),
          import('containers/PatientProfilePage/sagas'),
          import('containers/PatientProfilePage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([patientDashboardReducer, patientDashboardSagas, component]) => {
            injectReducer(
              'patientProfilePage',
              patientDashboardReducer.default,
            );
            injectSagas(patientDashboardSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/patient/membership-info',
      name: 'patientMembershipInfoPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/PatientProfilePage/reducer'),
          import('containers/PatientProfilePage/sagas'),
          import('containers/PatientMembershipInfoPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([patientDashboardReducer, patientDashboardSagas, component]) => {
            injectReducer(
              'patientProfilePage',
              patientDashboardReducer.default,
            );
            injectSagas(patientDashboardSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: '/patient/your-dentist',
      name: 'patientDentistPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/PatientProfilePage/reducer'),
          import('containers/PatientProfilePage/sagas'),
          import('containers/PatientDentistPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([patientDashboardReducer, patientDashboardSagas, component]) => {
            injectReducer(
              'patientProfilePage',
              patientDashboardReducer.default,
            );
            injectSagas(patientDashboardSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/patient/payment-update/:activationKey/:userId',
      name: 'patientPaymentUpdatePage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/PatientProfilePage/reducer'),
          import('containers/PatientProfilePage/sagas'),
          import('containers/PatientPaymentUpdatePage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([patientDashboardReducer, patientDashboardSagas, component]) => {
            injectReducer(
              'patientProfilePage',
              patientDashboardReducer.default,
            );
            injectSagas(patientDashboardSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/pn/:paymentUniqueId',
      name: 'PayNowRedirectPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/PatientProfilePage/reducer'),
          import('containers/PatientProfilePage/sagas'),
          import('containers/PayNowRedirectPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(
          ([patientDashboardReducer, patientDashboardSagas, component]) => {
            injectReducer(
              'patientProfilePage',
              patientDashboardReducer.default,
            );
            injectSagas(patientDashboardSagas.default);
            renderRoute(component);
          },
        );

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/privacy',
      name: 'privacy',
      getComponent(nextState, cb) {
        import('containers/PrivacyPage')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    /*{
      path: '/viewBAA/:EnvelopeId',
      name: 'viewBAA',
      {<Route exact path="/" render{(  => window.location = )}
      )}
    }, */
    // 5/11/22 turning off patient marketplace for now
    {
      path: '/search',
      name: 'search',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/SearchPage/reducer'),
          import('containers/SearchPage/sagas'),
          import('containers/SearchPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('search', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/terms',
      name: 'terms',
      getComponent(nextState, cb) {
        import('containers/TermsPage').then(loadModule(cb)).catch(errorLoading);
      },
    },
    {
      path: '/terms-employee',
      name: 'terms-employee',
      getComponent(nextState, cb) {
        import('containers/TermsEmployeePage')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      path: '/business-connect-terms',
      name: 'terms-employer',
      getComponent(nextState, cb) {
        import('containers/TermsEmployerPage')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      path: '/out-of-network-old',
      name: 'OutOfNetworkPage',
      getComponent(nextState, cb) {
        import('containers/OutOfNetworkPage')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      path: '/out-of-network',
      name: 'OutOfNetworkPageUpgrade',
      getComponent(nextState, cb) {
        import('containers/OutOfNetworkPageUpgrade')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      path: '/ppo-calculator',
      name: 'OutOfNetworkPagePPO',
      getComponent(nextState, cb) {
        import('containers/OutOfNetworkPagePPO')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      path: '/tos',
      onEnter: (nextState, replace) => {
        replace('/terms');
      },
      name: 'terms',
      getComponent(nextState, cb) {
        import('containers/TermsPage').then(loadModule(cb)).catch(errorLoading);
      },
    },
    {
      path: '/UAT-08152022',
      onEnter: (nextState, replace) => {
        replace('/enroll/practice');
      },
    },
    {
      path: '/enroll-practice',
      onEnter: (nextState, replace) => {
        replace('/enroll/practice');
      },
    },
    {
      path: '/business-connect-addendum',
      name: 'business-connect-addendum',
      getComponent(nextState, cb) {
        import('containers/BusinessConnectTermsPage')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      onEnter: redirectToLogin,
      path: findRoute('MultiLocationReports').path,
      name: 'MultiLocationReports',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('new/modules/MultiLocationReports/pages/MultiLocationReportsPage'),
        ]);
        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
        importModules.catch(errorLoading);
      },
    },
    {
      onEnter: redirectTo404,
      path: '*',
      name: '404',
      getComponent(nextState, cb) {
        import('containers/NotFoundPage')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
  ];
}
