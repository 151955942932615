import { SelectOption } from 'new/components/Form/Select';

export const pmsPlanOptions: SelectOption[] = [
  { value: 'officemate', label: 'OfficeMate' },
  { value: 'crystal', label: 'Crystal' },
  { value: 'revolution', label: 'Revolution' },
  { value: 'compulink', label: 'Compulink' },
  { value: 'other', label: 'Other' },
];

export const launchChecklistItems: Record<number, string> = {
  1: 'Finalize and activate your plans & pricing',
  2: '<a href="/account/marketing/">Print and display QR Code signs</a>',
  3: 'Send email and text blasts to all patients to promote your Membership Program',
  4: 'Create call-to-action on your website linking to the Patient Sign-Up Page',
  5: 'Additional questions? Email \n<a href="mailto:success@vision-hq.com" target="_blank">success@vision-hq.com</a>',
  6: 'Watch <a href="https://visionsource-uploads.s3.us-east-2.amazonaws.com/7.22.24+VHQ+TT.mp4" target="_blank">Team Training Video</a>',
};

export const leadSourceOptions = [
  'American Association of Vision Office Managers (AADOM)',
  'American Academy of Pediatric Optometry (AAPD)',
  'Association of Vision Support Organizations (ADSO)',
  'Dentist Entrepreneur Organization (DEO)',
  'Elite Vision Alliance',
  'Mommy Optometrists in Business (MDIB)',
  'North Carolina Vision Society (NCDS)',
  'Ohio Association (ODA)',
  'Oklahoma Association (OKDA)',
  'Gargle',
  'Google',
  'Nifty Thrifty',
  'Smile Source',
  'The Levin Group | Roger Levin',
];

export const templateOptions = [
  {
    value: '0',
    label: 'Classic',
    imageURL:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/demo_simple.jpg',
  },
  {
    value: '1',
    label: 'Blue & Green',
    imageURL:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/blue_and_green_enroll_2.jpg',
  },
  {
    value: '2',
    label: 'Yellow & Charcoal',
    imageURL:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/yellow_charcoal_2.jpg',
  },
];

export const jobTitleOptions: SelectOption[] = [
  {
    value: 'officeManager',
    label: 'Office Manager',
  },
  {
    value: 'frontDesk',
    label: 'Front Desk',
  },
  {
    value: 'treatmentCoordinator',
    label: 'Treatment Coordinator',
  },
  {
    value: 'financeInsuranceCoordinator',
    label: 'Finance / Insurance Coordinator',
  },
  {
    value: 'regionalManager',
    label: 'Regional Manager',
  },
  {
    value: 'marketingGuru',
    label: 'Marketing Guru',
  },
  {
    value: 'optometrist',
    label: 'Optometrist',
  },
  {
    value: 'executive',
    label: 'Executive',
  },
  {
    value: 'controller',
    label: 'Controller',
  },
  {
    value: 'other',
    label: 'Other',
  },
];
