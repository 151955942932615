import { Tenant } from 'new/types/tenants';
import pngLogo from 'new/assets/logos/VHQ-ondark.png';
import pngLogoSmall from 'new/assets/logos/VHQ-ondark.png';
import pngLogoOnLight from 'new/assets/logos/VHQ-onlight.png';
import { ReactComponent as SvgLogo } from 'new/assets/logos/VHQ-ondark.svg';
import { ReactComponent as SvgLogoSmall } from 'new/assets/logos/VHQ-ondark-small.svg';
import { ReactComponent as SvgLogoOnLight } from 'new/assets/logos/VHQ-onlight.svg';
import loginBg from 'new/assets/login_bg_vhq.png';
import {
  jobTitleOptions,
  launchChecklistItems,
  leadSourceOptions,
  pmsPlanOptions,
  templateOptions,
} from '../constants/const.vhq';
import favIcon from 'assets/favicon_vision.ico';

const vhq: Tenant = {
  id: 'vhq',
  name: 'VisionHQ',
  shortName: 'VHQ',
  nameSimple: 'Vision',
  supportEmail: 'support@vision-hq.com',
  successEmail: 'success@vision-hq.com',
  supportPhone: '(336) 777-5136',
  infoEmail: 'info@vision-hq.com',
  professionalName: 'optometrist',
  professionalNamePlural: 'optometrists',
  profession: 'optometry',
  ucrName: 'U&C',
  prodUrl: 'https://app.vision-hq.com',
  prodDomain: 'vision-hq.com',
  adminButtonColor: '#00b7af',
  features: {
    setupBaa: false,
    gpp: false,
    pms: false,
    marketingOnlyLinks: true,
    businessConnect: false,
    inhouseFinancing: false,
    defaultSpecialty: '1',
    upgradeOffice: false,
    seasonOfGivingWidget: true,
    feePerMember: false,
    leaderboardWidgetType: 'lightGreen',
    printMaterials: true,
    leadSource: false,
    upgradeFromOldPlatform: false,
    changePlanCoordinatorDoubleConfirm: false,
    csvSetup: false,
    payoutIssues: false,
    switchingBanner: false,
    canDisableFamilyDiscounts: true,
  },
  disabledWidgets: [
    'memberVsNonMemberData',
    'averageRevenue',
    'upcomingAppointments',
  ],
  constants: {
    launchRewards: {
      prize30: 25,
      prize60: 50,
      prize90: 100,
    },
    seasonOfGivingPrize: '$100, $50, and $25',
    seasonOfGivingImage:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+MMR+hat+in+circle.png',
    marketingTemplates:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+-+Email+%26+Text+Blasts+to+Patients+(Template).docx',
    ucrPrefix: '',
    login: {
      fontFamilyContainer: undefined,
      fontFamilyTitle: undefined,
    },
    externalPatientLandingBaseUrl: '/accounts/signup/my-optometrist',
    pmsPlanOptions,
    launchChecklistItems,
    leadSourceOptions,
    templateOptions,
    jobTitleOptions,
    defaultMonthlyPrice: 99,
    defaultYearlyPrice: 990,
    defaultChildStartingAge: 0,
    files: {
      scriptingPdf:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/Scripting+%2B+Daily+Prep.pdf',
      quickstartGuidePdf:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ_Quick+Reference+Guide.pdf',
      memberSignaturePdf:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+In+Office+Member+Agreement+(1)+(1).pdf',
      termsAndConditions:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/Optometric+Membership+Plan+-+Terms++Conditions-July+2024.pdf',
      termsAndConditionsSpanish:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/Optometric+Membership+Plan+-+Terms++Conditions-July+2024.pdf',
      openEnrollmentTemplate:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/%5BVHQ%5D+Open+Enrollment+Email+Templates.docx',
    },
    onboardingCalendly:
      'https://calendly.com/dhq-client-success/onboarding-assistance',
    associationLogo: {
      1: 'https://visionsource-uploads.s3.us-east-2.amazonaws.com/vslogo.png',
    },
    locationInfoList2: [],
    s3Path: 'https://visionsource-uploads.s3.amazonaws.com',
    hqOrigin: 'visionhq',
    secretaryOfStateUrl: 'https://visionhq.elevio.help/en/articles/50',
    successOnboardingCalendly:
      'https://calendly.com/vhqsuccess/onboarding?hide_gdpr_banner=1',
    elevioId: '64f7d94574d2f',
    redirectToDashboardPath: '/account/dashboard',
    quantityOptions: [
      { value: 250, label: '250', price: 210, higherPrice: 210 },
      { value: 500, label: '500', price: 335, higherPrice: 335 },
      { value: 750, label: '750', price: 415, higherPrice: 415 },
      { value: 1000, label: '1000', price: 480, higherPrice: 480 },
    ],
    planCountsMarketingMaterials: 3,
    marketingMaterialsType: 'brochure',
  },
  elevio: {
    cancellations: '105',
    trackPayments: '25',
    pricing: '106',
  },
  logos: {
    pngLogo,
    pngLogoOnLight,
    pngLogoSmall,
    SvgLogo,
    SvgLogoSmall,
    SvgLogoOnLight,
    favIcon,
  },
  assets: {
    loginBg,
    printMaterialsFront:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ%2BBrochure%2BMockup_small.jpg',
  },
  theme: {
    modal: {
      fontFamily: 'Roboto',
    },
  },
  awsStorageUrl: 'https://visionsource-uploads.s3.amazonaws.com',
};

export default vhq;
